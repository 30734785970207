<template src="./details.html"></template>

<script>
import Http from '@/shared/http-config'
import forms from '../Forms'
import nav from '../../cms/nav-recursion/NavRecursion'
import draggable from 'vuedraggable'

export default {
  name: "Details",
  components: {draggable},
  mixins: [forms, nav],
  data: function () {
    return {
      form: {
        id: 0,
        language: null,
        redirect_to: null,
        title: '',
        fields: [],
      },
      fields: [],
      languages: [],
      navigation: [],
      restricted_types: ['first_name', 'last_name', 'email'],
      field_types: [
        {
          text: 'Vorname',
          value: 'first_name',
        },
        {
          text: 'Nachname',
          value: 'last_name',
        },
        {
          text: 'E-Mail',
          value: 'email',
        },
        {
          text: 'Mobil / Handy',
          value: 'mobile',
        },
        {
          text: 'Telefon',
          value: 'phone',
        },
        {
          text: 'Straße / Hausnummer',
          value: 'street',
        },
        {
          text: 'PLZ',
          value: 'zip',
        },
        {
          text: 'Ort',
          value: 'city',
        },
        {
          text: 'Geburtsdatum',
          value: 'birthday',
        },
        {
          text: 'Geburtsort',
          value: 'birth_place',
        },
        {
          text: 'Filiale',
          value: 'branch',
        },
        {
          text: 'Text-Feld Einzeilig',
          value: 'input',
        },
        {
          text: 'Text-Feld Mehrzeilig',
          value: 'textarea',
        },
        {
          text: 'Datum',
          value: 'date',
        },
        {
          text: 'Uhrzeit',
          value: 'time',
        },
        {
          text: 'Datum & Uhrzeit',
          value: 'datetime',
        },
        {
          text: 'Drop-Down',
          value: 'select'
        },
        {
          text: 'Checkbox',
          value: 'checkbox',
        },
        {
          text: 'Radio-Button',
          value: 'radio',
        },
      ],
    }
  },
  watch: {
    'form.language': function(to, from) {
      this.loadNavigation();
    }
  },
  created: function(){
    let self = this;
    Http.request('GET', '/website/template').then((templates) => {
      templates.data.forEach(function (template, key) {
        Http.request('GET', '/website/language/' + template.id).then((languages) => {
          languages.data.forEach(function (language) {
            self.languages.push({
              text: template.name + ' | ' + language.name,
              value: language.id
            });
          });
          if (key === templates.data.length-1 && self.languages.length === 1) {
            self.form.language = self.languages[0].value;
          }
        });
      });
    });

    if(parseInt(self.$route.params.id) > 0){
      Http.request('GET', '/forms/' + self.$route.params.id).then((form) => {
        self.form = form.data;

        let fields = {};
        self.form.fields.forEach(function (field) {
          if(typeof fields[field.row] === "undefined"){
            fields[field.row] = {};
            fields[field.row].fields = [];
          }
          fields[field.row].fields.push(field);
        });

        Object.keys(fields).forEach(key => {
          self.fields.push(fields[key]);
        });

        self.loadNavigation();
      });
    }
  },
  methods: {
    loadNavigation: function () {
      let self = this;
      Http.request('GET', '/website/navigation/list/' + this.form.language).then((navigation) => {
        self.navigation = self.navRecursion([], navigation.data, 0);
      });
    },
    addRow: function () {
      this.fields.push({fields: []});
    },
    addField: function () {
      this.fields[this.fields.length - 1].fields.push({
        id: null,
        label: '',
        required: false,
        type: null,
        placeholder: null,
        options: [],
      });
    },
    checkMove: function(evt){
      if(evt.relatedContext.list.length >= 2){
        return false;
      }
      return true;
    },
    removeField: function (index, index2) {
      this.$delete(this.fields[index].fields, index2);
    },
    removeRow: function (index) {
      this.$delete(this.fields, index);
    },
    save(){
      let self = this;
      let fields = [];
      self.fields.forEach(function (row, index) {
        row.fields.forEach(function (field) {
          field.row = index;
          fields.push(field);
        });
      });
      self.form.fields = fields;

      Http.request('POST', '/forms/' + self.form.id, self.form).then(() => {
        self.$router.push('/website/forms');
      });
    },
  }
}
</script>

<style scoped></style>
