<template src="./forms.html"></template>

<script>
import Http from '@/shared/http-config'

export default {
  name: "Forms",
  mounted: function(){
    this.$store.dispatch('setIntroJsEnabled', false);
  },
  destroyed: function(){
    this.$store.dispatch('setIntroJsEnabled', true);
  },
  data: function () {
    return {
      fields: [
        { key: 'title', label: 'Titel'},
        { key: 'actions', label: '', tdClass: 'text-right'},
      ],
      forms: [],
      meta: {}
    }
  },
  methods: {
    loadForms(ctx, callback) {
      let self = this;
      const params = '?page=' + ctx.currentPage + '&per_page=' + ctx.perPage;
      Http.request('GET', '/forms' + params).then(function (forms) {
        self.meta = forms.meta;
        self.forms = forms.data;
        callback(forms.data)
      });
      return null
    },
    deleteForm: function (form) {
      this.$bvModal.msgBoxConfirm('Möchtest du dieses Formular wirklich löschen?', {
        headerBgVariant: 'primary',
        title: 'Formular löschen?',
        okTitle: 'Ja',
        cancelTitle: 'Nein',
        cancelVariant: 'outline-danger'
      })
        .then(value => {
          if(value === true){
            let self = this;
            Http.request('DELETE', '/forms/' + form.id).then(() => {
              if(typeof(self.$refs.formTable) !== "undefined"){
                self.$refs.formTable.refresh();
              }
              else{
                self.$router.push('/website/forms');
              }
            });
          }
        });
    }
  }
}

</script>

<style scoped></style>
